import { createNamespacedHelpers } from 'vuex'

import mixin from 'src/utils/mixin'

import DataTable from 'src/tools/components/table/DataTable'
import SummaryCardBar from 'src/tools/components/summary/SummaryCardBar'
import SummaryCard from 'src/tools/components/summary/SummaryCard'

const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  components: {
    SummaryCard,
    SummaryCardBar,
    DataTable,
  },
  computed: {
    ...mapGetters(['currentColumns', 'currentSummaries', 'reportKey']),
    ...mapState({
      displayFilter: (state) => state.displayFilter,
      filterData: (state) => state.filterData,
      items: (state) => state.items,
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      selectedSummary: (state) => state.selectedSummary,
      subId: (state) => state.subId,
    }),
  },
  ...mixin,
}
